<template>
  <div id="gallery" class="container box">
    <div v-if="pending">
      <h1 class="title"> {{ gallery.Title}}</h1>
      <vue-simple-markdown v-if="discriptionOn" :source="source"></vue-simple-markdown>
      <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
      <div class="columns is-hidden-mobile">
      <div class="column">
        <div class="columns is-multiline">
          <div class="column is-one-fifth" v-for="(image, i) in images" :key="i" @click="index = i">
             <b-image
                    :src="image" :key="i"
                    ratio="1by1"
                    class="image"
                    alt="The Buefy Logo"
                    ></b-image>
          </div>
        </div>
      </div>
      </div>
      <div class="is-hidden-desktop">
       <b-button @click="index = 0">Gallerie öffnen</b-button>
    </div>
    </div>
    <div v-else class="box">
      <h1 class="title"> Loading . . .</h1>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
name:"galleryperview",
    components:{
      VueGallerySlideshow
    },
    data(){
        return {
            pending: false,
            count: 0,
            index: null,
            gallery: null
        }
    },
    computed:{
        source(){
          return this.gallery.Beschreibung
        },
        discriptionOn(){
          return this.gallery.BeschreibungAnzeigen
        },
        galleryURL(){
            return this.$axios.defaults.baseURL+ "/" + this.$route.params.tagName +"/" + this.$route.params.id
        },
        images(){
          let images = []
            for(let image of this.gallery.BilderGallerie){
              if(image.formats){
                images.push(this.$axios.defaults.baseURL+image.formats.medium.url)
              }
            }
            
          return images
        }

    },
    async mounted(){
        this.reload()
    },
    methods:{
        async reload(){
          
            this.pending = false
            this.gallery = null
            this.gallery = await this.$get(this.galleryURL)
            this.pending = true
        }
    },
    watch: {
        '$route.params.tagName'(){
           this.reload()
        } 
    }
}
</script>

<style>

</style>