<template>
  <div class="container has-text-centered">
    <div v-if="pending" class="columns">
        <div class="column">
            <h1 class="title"> {{this.title}}</h1>
            <div class="columns ">
                <div class="column">
                   
                     <b-image
                        :src="this.mainPicURL"
                        alt="A random image"
            ></b-image>
            
           
            </div>
            <div class="column">
                 <vue-simple-markdown :source="source"></vue-simple-markdown>
            </div>
    </div>
    </div>
    
    </div>
    <div v-else class="box">
              <h1 class="title"> Loading . . .</h1>
    </div>
    
  </div>
</template>

<script>
export default {
    name:"Catering",
    data(){
    return {
      story: {},
      pending: false
    }
  },
  computed:{
   
    mainPicURL(){
      
      return this.$axios.defaults.baseURL + this.story.Catering.formats.large.url;
    },
    source(){
     return this.story.Inhalt;
    },
    title(){
        return this.story.Titel;
    }
  },
  async mounted() {
    this.pending = false
    this.story = await this.$get("/catering-uebersicht")
    this.pending = true
  },
}
</script>

<style>

</style>