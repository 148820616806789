<template>
  <div class="trip container">
      <h1 class="title">Tourismus</h1>
    <div v-if="pending" class="columns">
        <div class="column is-three-fifths is-offset-one-fifth">
            <b-table :data="data">
                <b-table-column field="id" label="Ziele in der Umgebung"  :centered="true" numeric v-slot="props">
                    <a :href="props.row.Link" class="is-link">{{props.row.Name}}</a>
                </b-table-column>
            </b-table>
        </div>
    </div>
    <div v-else class="box">
        <h1 class="title"> Loading . . .</h1>
    </div>
      
  </div>
</template>

<script>
    export default {
        data() {
            return {
                data: null,
                pending: false
            }
        },
        async mounted(){
        this.reload()
        },
        methods:{
          async reload(){
            this.pending = false
            this.data = null
            this.data = await this.$get('/tourismuses')
            this.pending = true
          }
        },
    }
</script>

<style>

</style>